/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.sidebar-logo {
    padding: 30px;
    padding-top: 0px;
    padding-bottom: 15px;
    margin-top: 20px;
}

ion-app {
    max-width: 1023px;
    margin: 0 auto;    
}

body {
    background-color: rgb(50,50,50);
}

ion-card-title {
    --size: 22px;
    --font-size: 22px;
    font-size: 22px;
}

.md ion-content.background {
    --background: url('assets/img/app_bg.jpg') 0 0/100% 100% no-repeat;
}

.ios ion-content.background {
    --background: #a0d9f7;
}

ion-card.background-white {
    --background: white;
    --color: rgb(20,20,20);
}

ion-item.transparent {
    background-color: transparent;
    --background: transparent;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.28+0,0+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,0.28) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0.28) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0.28) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#47ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
}

ion-item.full-transparent {
    background-color: transparent;
    --background: transparent;
}

ion-item.transparent.disabled-link:hover, ion-item.transparent.disabled-link:active, ion-item.transparent.disabled-link:focus {
    background-color: transparent;
    --background: transparent;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.28+0,0+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,0.28) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0.28) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0.28) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#47ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */    
}
ion-list.transparent {
    background-color: transparent;
    --background: transparent;
}
ion-item-divider {
    --background: white;
}
ion-menu ion-item-divider {
    --background: rgb(235,235,235);
}

.margin-bottom {
    margin-bottom: 15px;
}

.h-center {
    text-align: center;
    display: block;
}

.center {
    padding: 15px;
    text-align: center;
}

#main-content .os-input .native-input {
    background-color: rgba(255,255,255,0.8);
    margin-bottom: 15px;
    padding: 15px;
}

#main-content a.color-dark, #main-content .color-dark {
    color: #222428;
}
